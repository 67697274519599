// ------------------
// UTILS
// ------------------
export type AnyJson = boolean | number | string | null | JsonArray | JsonMap;

export interface JsonMap {
  [key: string]: AnyJson;
}

export type JsonArray = AnyJson[];

// ------------------
// ENUM
// ------------------
export enum GameResultEnum {
  DEFEAT = 0, // red
  VICTORY = 1, // blue
}

export enum ModalTypesEnum {
  ERROR_MSG = 'ErrorMsg',
  PARTITION_SELECT = 'PartitionSelect',
}

// ------------------
// ITEM
// ------------------
export interface CharacterItem {
  name: string;
  headId: number;
  headUrl: string;
  partition: string;
}

export interface GameItem {
  champion: string;
  type: string;
  kda: string;
  startTime: string;
  gameId: string;
  gameResult: GameResultEnum;
}

export interface ModalItem {
  id: string; // random unique key
  name: ModalTypesEnum; // modal component name
  type: string; // modal layout type
  group: string; // group by api related action for clear
  keepAlive: boolean; // keep the modal in the back
  data: any; // define other special props
}

// ------------------
// RESPONSE DATA
// ------------------
export type APIResponse<T> = Promise<T | ErrorResponse>;

export interface ErrorResponse {
  error: string;
}

export interface CharacterResponse {
  characters: CharacterItem[];
}

export interface GameResponse {
  games: GameItem[];
}
