import { defineStore } from 'pinia';
import { ModalItem } from '@/services/types';

interface ModalStateType {
  modalStack: ModalItem[];
}

const ModalState = (): ModalStateType => ({
  modalStack: [],
});

export const useModalStore = defineStore('modal', {
  state: ModalState,
  getters: {
    currentModal({ modalStack }): ModalItem {
      if (modalStack.length > 0) {
        return modalStack[modalStack.length - 1];
      }
      return {} as ModalItem;
    },
  },
  actions: {
    createModal(options: Partial<ModalItem>) {
      const modalOptions = {
        id: Math.random().toString().slice(2),
        type: '',
        group: '',
        keepAlive: false,
        data: {},
        ...options,
      } as ModalItem;

      return {
        value: modalOptions,
        add: () => this.addModal(modalOptions),
        replace: () => this.replaceModal(modalOptions),
      };
    },
    addModal(options: ModalItem) {
      this.modalStack.push(options);
    },
    replaceModal(options: ModalItem) {
      this.modalStack[this.modalStack.length - 1] = options;
    },
    closeModal() {
      this.modalStack.pop();
    },
    clearModal(groupName?: string) {
      if (groupName) {
        const len = this.modalStack.length;
        let i = 0;
        for (let j = 0; j < len; j++) {
          const modalItem = this.modalStack[j];
          if (modalItem.group !== groupName) {
            this.modalStack[i++] = modalItem;
          }
        }
        this.modalStack.length = i;
      } else {
        this.modalStack = [];
      }
    },
  },
});
