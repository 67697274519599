import { createI18n, useI18n } from 'vue-i18n';
import messages from './node';

const langMap = JSON.parse(process.env.LANG_MAP);
const regionPostfix =
  process.env.REGION === 'sg' ? '' : `-${process.env.REGION}`;

const loadedLocales = {};

const fetchMessage = async (locale) => {
  try {
    const res = await fetch(
      `https://rosetta${regionPostfix}.garenanow.com/transify/${process.env.TRANSIFY_ID}?lang=${langMap[locale]}`,
    );
    if (res.status === 200) {
      return res.json();
    }
    throw Error(`Fetch Rosetta failed with status ${res.status}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Referring to local translation due to', e);
    return messages[locale];
  }
};

function setLocale(locale, _i18n) {
  const i18n = _i18n || useI18n();
  i18n.locale.value = locale;
  const [region, lang = region] = locale.split('_');
  document.documentElement.dataset.region = region;
  document.documentElement.lang = lang;
  return locale;
}

export async function loadLocale(locale, _i18n) {
  const i18n = _i18n || useI18n();

  if (locale in loadedLocales) {
    return setLocale(locale, i18n);
  }

  const message = await fetchMessage(locale);
  i18n.setLocaleMessage(locale, message);
  loadedLocales[locale] = true;
  return setLocale(locale, i18n);
}

export default createI18n({
  legacy: false,
  locale: process.env.LOCALE,
  fallbackLocale: process.env.LOCALE,
  globalInjection: true,
  messages,
});
