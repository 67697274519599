import axios, { AxiosPromise } from 'axios';
import utils from '@/utils';
import { AnyJson, APIResponse } from './types';

const API = '/api';

// remove all queries
// window.history.replaceState('', '', '/');

export const instance = axios.create({
  baseURL: API,
  headers: {
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
  timeout: 5000,
});

instance.interceptors.request.use(function (config) {
  if (process.env.NODE_ENV === 'development') {
    console.log(`request ${config.url}`, config);
  }
  if (config.headers) {
    config.headers['Access-Token'] = localStorage.getItem('access_token') || '';
    config.headers['Partition'] = localStorage.getItem('partition') || 0;
  }
  return config;
});

instance.interceptors.response.use(function (response) {
  if (process.env.NODE_ENV === 'development') {
    console.log(`response ${response.config.url}`, response);
  }
  return response;
});

interface RequestMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: <T>(url: string, data: any) => AxiosPromise<T>;
}

const requestMap: RequestMap = {
  get: (url, data) => instance.get(url, { params: data }),
  put: (url, data) => instance.put(url, data),
  delete: (url, data) => instance.delete(url, { params: data }),
  post: (url, data) => instance.post(url, data),
};

export default function <R>(
  method: string,
  url: string,
  data: AnyJson = null,
): APIResponse<R> {
  const requestType = method.toLowerCase();
  const normalizedParams = utils.snakifyKeys(data);
  const requestHandler = requestMap[requestType];

  if (requestHandler) {
    return requestHandler(url, normalizedParams).then((res) =>
      utils.camelizeKeys(res.data),
    ) as APIResponse<R>;
  }
  throw new Error(
    `Unknown request method of ${method}! You might have a typo.`,
  );
}
