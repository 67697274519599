export default () => {
  // force single page application
  function setRootFontSize() {
    const designSafeWidth = 750;
    const designSafeHeight = 1334;

    const vw = window.innerWidth;
    const vh = window.innerHeight;
    const vwToFontSize = (vw / designSafeWidth) * 100;
    const fontSize = Math.min(vwToFontSize, 100);

    // set root font size and html viewport
    const elem = document.documentElement;
    elem.style.fontSize = `${fontSize}px`;
    elem.style.width = `${vw}px`;
    elem.style.height = `${vh}px`;
  }

  // debounce
  let resetTimer: ReturnType<typeof setTimeout>;
  function resetRootFontSize() {
    clearTimeout(resetTimer);
    resetTimer = setTimeout(() => {
      setRootFontSize();
    }, 200);
  }

  setRootFontSize();
  window.addEventListener('resize', resetRootFontSize);
};
