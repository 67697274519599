function install(app: any) {
  const cdn = process.env.CDN;

  app.directive(
    'bg',
    (el: { style: { backgroundImage: string } }, binding: { value: any }) => {
      el.style.backgroundImage = `url('${cdn}/${binding.value}')`;
    },
  );

  app.directive('src', (el: { src: string }, binding: { value: any }) => {
    el.src = `${cdn}/${binding.value}`;
  });

  app.directive('mask', (el: any, binding: { value: any }) => {
    el.style.maskImage = `url('${cdn}/${binding.value}')`;
    el.style.webkitMaskImage = `url('${cdn}/${binding.value}')`;
  });
}

export default {
  install,
};
