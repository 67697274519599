import { createApp } from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueGopAuthenticate from '@garenatwdev/vue-gop-authenticate';
import App from '@ui/App.vue';
import common from '@ui/common';
import { createPinia } from 'pinia';
import i18n from '@/i18n';
import '@/preloader';
import '@ui/styles/global.scss';
import setupLayout from '@/layout';

setupLayout();

const app = createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(common)
  .use(VueGopAuthenticate, {
    env: process.env,
    opt: {
      game: 'aov',
      storageType: 'localStorage',
      storageNamespace: '',
      devGopHost: 'https://auth.garena.com',
    },
  });

app.mount('#root');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://63d58c8527464fa283998c08694ee71f@sentry.twrevenue.garenanow.com/236',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    integrations: [new Integrations.Vue({ Vue: app, attachProps: true })],
  });
}
