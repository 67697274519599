<template>
  <button class="close_btn"></button>
</template>

<style lang="scss" scoped>
.close_btn {
  position: absolute;
  background: assetsUrl('btn-cancel.svg');
  @include bgCenter();
  top: 0.15rem;
  right: 0.36rem;
  width: 0.6rem;
  height: 0.6rem;
}
</style>
