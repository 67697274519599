import { APIResponse } from '@/services/types';
import { useBase } from '@/store';

export async function useApi<R>(api: () => APIResponse<R>): Promise<R | null> {
  const { setIsLoading, handleError } = useBase();
  setIsLoading(true);

  try {
    const response = await api();

    if (response && typeof response === 'object' && 'error' in response) {
      handleError(response);
    } else {
      return response;
    }
  } catch (err) {
    handleError({ error: 'ERROR__SERVER_ERROR' });
  } finally {
    setIsLoading(false);
  }

  return null;
}
