import { defineStore } from 'pinia';
import services from '@/services';
import { CharacterItem, GameItem } from '@/services/types';
import { useGop } from '@garenatwdev/vue-gop-authenticate';
import { useApi } from '@/store/utils';
import { useBase } from '@/store';

export type GameStateType = {
  characters: CharacterItem[] | null;
  selectedCharacter: CharacterItem | null;
  games: GameItem[] | null;
  isInitReady: boolean;
};

const GameState = (): GameStateType => ({
  characters: null,
  selectedCharacter: null,
  games: null,
  isInitReady: false,
});

export const useGameStore = defineStore('game', {
  state: GameState,
  getters: {},
  actions: {
    async init() {
      const $gop = useGop();
      await $gop.loginCheck();

      if ($gop.gopState.storage.getItem('access_token')) {
        await this.getCharacters();
      }
      if (this.characters && $gop.gopState.storage.getItem('partition')) {
        const selectedIndex = this.characters.findIndex(
          (ch) => ch.partition === $gop.gopState.storage.getItem('partition'),
        );
        this.selectedCharacter = this.characters[selectedIndex];
        await this.getGames();
      }

      this.isInitReady = true;
    },
    async login() {
      const { setIsLoading } = useBase();
      const $gop = useGop();
      setIsLoading(true);

      const loginUrl = await $gop.getLoginUrl({
        platform: 'garena',
      });
      window.location.href = loginUrl;
    },
    logout() {
      const $gop = useGop();

      $gop.logoutWithRedirect();
    },
    async getCharacters() {
      const res = await useApi(services.getCharacter);
      if (res) {
        this.characters = res.characters;
      }
    },
    async selectCharacter(character: CharacterItem) {
      const $gop = useGop();

      this.selectedCharacter = character;
      $gop.gopState.storage.setItem('partition', character.partition);
      await $gop.loginCheck();
      this.getGames();
    },
    async getGames() {
      const res = await useApi(services.getGame);
      if (res) {
        this.games = res.games;
      }
    },
    clearCharacter() {
      const $gop = useGop();

      this.games = null;
      this.selectedCharacter = null;
      $gop.gopState.storage.removeItem('partition');
    },
  },
});
