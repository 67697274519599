/*
 * image preloader
 */

/* eslint-disable global-require */
const images: string[] = [
  // 'background.jpg',
];

images.forEach((image) => {
  new Image().src = `${process.env.CDN}/${image}`;
});
