import api from './api';
import { CharacterResponse, GameResponse } from './types';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

export * from './types';

export default {
  getCharacter: () => api<CharacterResponse>('get', '/character'),
  getGame: () => api<GameResponse>('get', '/game'),
};
