function getParam(paramName: string, url?: string): string | null {
  const href = url || window.location.href;
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(href);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str: string): string {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

function snakifyStr(str: string): string {
  if (str.length === 1) return str;
  const r = str.replace(/(?:^|\.?)([A-Z])/g, (_, x) => `_${x.toLowerCase()}`);
  return r[0] === '_' ? r.slice(1) : r;
}

function padDateStart(val: number | string) {
  const string = typeof val === 'number' ? String(val) : val;
  return string.padStart(2, '0');
}

function convertCase(convertFunc: Function) {
  function converter(thing: any): string | string[] {
    if (thing instanceof Array) {
      return thing.map((i) => converter(i)) as string[];
    }
    if (thing instanceof Object) {
      const newObj: any = {};
      Object.keys(thing).forEach((k) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  padDateStart,
  delay,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
};
