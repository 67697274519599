import { defineStore } from 'pinia';
import { ModalTypesEnum, ErrorResponse } from '@/services/types';
import { useModal } from '@/store';

interface BaseStateType {
  isLoading: boolean;
  isFreezing: boolean;
  errorMsg: string;
}

const BaseState = (): BaseStateType => ({
  isLoading: false,
  isFreezing: false,
  errorMsg: '',
});

export const useBaseStore = defineStore('base', {
  state: BaseState,
  actions: {
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setIsFreezing(isFreezing: boolean) {
      this.isFreezing = isFreezing;
    },
    handleError({ error }: ErrorResponse) {
      const { createModal } = useModal();
      this.errorMsg = error;

      createModal({ name: ModalTypesEnum.ERROR_MSG }).add();
    },
  },
});
